import { createTheme } from '@mui/material/styles';
import { ruRU } from '@mui/x-data-grid';
import { ruRU as coreRu } from '@mui/material/locale';
import BasicFontsList from 'common/font';

const globalStyles = `body, html, #root {
  height: 100%;
  width: 100%;
}`;

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#377FD9',
        light: '#83CBFB',
        dark: '#C3C3C3',
      },
      secondary: {
        main: '#fff',
      },
      background: {
        default: '#fff',
      },
    },
    typography: {
      fontFamily: [
        'Roboto',
        'Arial',
        'Helvetica',
        'Times',
        'Georgia',
        'sans-serif',
        'serif',
        '-apple-system',
        'system-ui',
        'monospace',
      ].join(','),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: BasicFontsList.concat(globalStyles),
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            userSelect: 'none',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          action: {
            marginLeft: 0,
          }
        }
      }
    },
  },
  ruRU,
  coreRu
);
export default theme;
