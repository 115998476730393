import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { IDataResponse } from 'types';

type ServerError = { status: number; message: string };

const fetchPrevResult = createAsyncThunk<
  IDataResponse,
  void,
  { extra: AxiosInstance; rejectValue: string }
>('@@contragents/fetch-prev-result', async (_, { extra: api, rejectWithValue }) => {
  try {
    const response: AxiosResponse<IDataResponse> = await api.get('/last-results/prev');
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<ServerError>;
      return rejectWithValue(error.response ? error.response.data.message : 'Что-то пошло не так');
    } else {
      return rejectWithValue('Что-то пошло не так');
    }
  }
});
export default fetchPrevResult;
