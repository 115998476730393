export const enum FIELD_NAMES {
  ID = '№',
  INN = 'ИНН',
  ORGNAME = 'Наименование компании',
  EGRULSTATUSTE = 'ЕГРЮЛ: статус ТЭ',
  EGRULINFO = 'ЕГРЮЛ: инфо',
  KODEKS1 = 'Кодекс: статус 1',
  KODEKS2 = 'Кодекс: статус 2',
  NORMACS = 'NormaCS',
  EXPRDATE = 'Срок закрепления ТЭ',
  DISTRIBUTERTE = 'Дистрибьютор ТЭ',
  ISUPBSTATUS = 'ЕГРЮЛ: статус ИСУПБ',
  ISUPBINFO = 'ЕГРЮЛ: инфо ИСУПБ',
  HOLDING = 'Холдинг',
}
