export function toLocale(date: Date): string {
  if (!date) {
    return '-';
  }
  return new Date(date).toLocaleTimeString('ru', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}
