import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import ReactDOM from 'react-dom/client';
import store from 'store';

import App from './App';
import theme from './common/theme';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>
);
reportWebVitals();
