import { toLocale } from 'util/dates';

import Typography from '@mui/material/Typography';
import { useAppSelector } from 'store';
import { selectContragentsDate } from 'store/selectors';

export function TableName(): JSX.Element {
  const date = useAppSelector(selectContragentsDate);
  const formatedDate = date && toLocale(date);

  return (
    <Typography
      color={'primary.main'}
      variant={'h5'}
      sx={{ flex: 1 }}
      fontSize={20}
      textTransform='uppercase'
    >
      список контрагентов {formatedDate}
    </Typography>
  );
}
