import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const ChipBox = styled(Box)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 3,
    padding: '12px 12px',
  },
  '& .MuiFilledInput-input': {
    padding: 0,
    width: '10%',
    textOverflow: 'ellipsis',
  }
}));
export default ChipBox;