import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { IContragent, Status } from 'types';

import Toolbar from './Toolbar';
import columns from './columns';

interface ITableProps {
  data: IContragent[];
  status: Status;
}

export default function Table(props: ITableProps): JSX.Element {
  return (
    <Box height={500} width='100%'>
      <DataGridPro
        rows={props.data}
        checkboxSelection
        columns={columns}
        loading={props.status === 'loading'}
        disableSelectionOnClick
        components={{ Toolbar, LoadingOverlay: LinearProgress }}
      />
    </Box>
  );
}
