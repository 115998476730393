import { FIELD_NAMES } from 'constants/headerTableNames';

import { GridColDef } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  { field: 'id', headerName: '№ п/п', width: 60 },
  {
    field: 'inn',
    headerName: FIELD_NAMES.INN,
    width: 100,
  },
  {
    field: 'orgName',
    headerName: FIELD_NAMES.ORGNAME,
    type: 'string',
    width: 500,
  },
  {
    field: 'egrulStatusTe',
    headerName: FIELD_NAMES.EGRULSTATUSTE,
    type: 'string',
    width: 350,
    sortable: true,
  },
  {
    field: 'egrulInfo',
    headerName: FIELD_NAMES.EGRULINFO,
    type: 'string',
    sortable: true,
    width: 300,
  },
  {
    field: 'kodeks1',
    headerName: FIELD_NAMES.KODEKS1,
    type: 'string',
    sortable: true,
    width: 300,
  },
  {
    field: 'kodeks2',
    headerName: FIELD_NAMES.KODEKS2,
    type: 'string',
    sortable: true,
    width: 200,
  },
  {
    field: 'normaCS',
    headerName: FIELD_NAMES.NORMACS,
    type: 'string',
    sortable: true,
    width: 200,
  },
  {
    field: 'exprDate',
    headerName: 'Срок закрепления',
    type: 'string',
    sortable: false,
    width: 140,
  },
  {
    field: 'distributerTe',
    headerName: FIELD_NAMES.DISTRIBUTERTE,
    type: 'string',
    sortable: true,
    width: 400,
  },
  {
    field: 'isupbStatus',
    headerName: FIELD_NAMES.ISUPBSTATUS,
    type: 'string',
    sortable: true,
    width: 400,
  },
  {
    field: 'isupbInfo',
    headerName: FIELD_NAMES.ISUPBINFO,
    type: 'string',
    sortable: true,
    width: 400,
  },
  {
    field: 'holding',
    headerName: FIELD_NAMES.HOLDING,
    type: 'string',
    sortable: true,
    width: 400,
  },
];
export default columns;
