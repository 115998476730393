import RobotoMedium from './Roboto-Medium.ttf';
import RobotoRegular from './Roboto-Regular.ttf';
import RobotoBold from './Roboto-Bold.ttf';

class Font {
  private readonly fontDisplay: string = 'swap';
  private readonly unicodeRange: string = 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116';

  constructor(
    private readonly fontFamily: string,
    private readonly fontName: string,
    private readonly fontWeight: 400 | 500 | 600,
    private readonly fontStyle: 'normal' | 'italic',
    private readonly format: 'truetype' | 'woff' | 'woff2',
    private readonly src: string
  ) {}

  private createFontFace(): string {
    return `@font-face {
      font-family: '${this.fontFamily}';
      font-style: ${this.fontStyle};
      font-display: ${this.fontDisplay};
      font-weight: ${this.fontWeight};
      src: local('${this.fontName}'), url(${this.src}) format('${this.format}');
      unicodeRange: ${this.unicodeRange};
      }`;
  }

  static generateFontParameters(...fontList: Font[]): string {
    return fontList.map((font) => font.createFontFace()).join('\n');
  }
}

const BasicFontsList: string = Font.generateFontParameters(
  new Font('Roboto', 'Roboto-Bold', 600, 'normal', 'truetype', RobotoBold),
  new Font('Roboto', 'Roboto-Medium', 500, 'normal', 'truetype', RobotoMedium),
  new Font('Roboto', 'Roboto-Regular', 400, 'normal', 'truetype', RobotoRegular)
);

export default BasicFontsList;
