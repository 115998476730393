import { BASE_URL, PORT } from 'constants/api';

import axios from 'axios';

const $host = axios.create({
  baseURL: BASE_URL + ':' + PORT,
  //timeout: 5000, решить какой поставить в дальнейшем
});

export default $host;
