import { BASE_URL, PORT } from 'constants/api';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import { Fragment, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ISteps } from 'types';
import { useAppDispatch } from 'store';
import SearchContragents from 'components/SearchContragents';
import { io } from 'socket.io-client';
import ProgressComponent from 'components/Progress/ProgressComponent';
import { setContragents } from 'store/slices/contragent';
import ContragentsData from 'components/ContragentsData';
import Alert from '@mui/material/Alert';

const socket = io(`${BASE_URL}:${PORT}`);
socket.emit('check');

export default function StepperComponent(): JSX.Element {
  const [activeStep, setActiveStep] = useState(0);
  const [isConnect, setIsConnect] = useState(socket.connected);

  const dispatch = useAppDispatch();

  useEffect(() => {
    //Проверяем на коннект
    socket.on('connect', () => {
      setIsConnect(true);
      //Проверяем запускался ли парсер ранее
      socket.on('check', (arg) => {
        if (arg === 'launched') {
          setActiveStep(1);
        } else if (arg === 'stopped') {
          setActiveStep(0);
        } else {
          setActiveStep(2);
        }
      });

      //Прослушиваем сообщения 'events'
      socket.on('events', (arg) => {
        dispatch(setContragents(arg));
        setActiveStep(2);
      });
    });

    socket.on('disconnect', () => {
      setIsConnect(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackToInput = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 2);
  };

  const steps: ISteps[] = [
    {
      label: 'Введите ИНН',
      description:
        'Можно ввести один или более ИНН, разделенных пробелом или переводом строки. Чтобы добавить введенный ИНН, нажмите ввод, пробел, TAB, запятую или точку с запятой.',
      content: <SearchContragents socket={socket} isConnect={isConnect} />,
      action: [],
    },
    {
      label: 'Процесс выполнения',
      description:
        'Здесь отображается процесс выполнения выгрузки. Отмена процесса может занимать несколько секунд. После успешного завершения отобразится следующий шаг.',
      content: <ProgressComponent socket={socket} />,
      action: [],
    },
    {
      label: 'Выгрузите данные из таблицы',
      description:
        'По кнопке "Выгрузить" выгружается общий файл по всем контрагентам. Так же возможно получение последних и предпоследних выгруженных ранее данных.',
      content: <ContragentsData handleBackToInput={handleBackToInput} />,
      action: [],
    },
  ];

  return (
    <Box maxWidth='100%' mt={3}>
      {isConnect ? (
        <Alert color='success'>Соединение с сервером установлено</Alert>
      ) : (
        <Alert color='warning'>Соединение с сервером не установлено</Alert>
      )}
      <Stepper activeStep={activeStep} orientation='vertical' sx={{ mt: 2 }}>
        {steps.map(({ label, description, content, action }, index) => (
          <Step key={label}>
            <StepLabel
              optional={
                index + 1 === steps.length ? (
                  <Typography variant='caption'>Последний шаг</Typography>
                ) : null
              }
            >
              {label}
            </StepLabel>
            <StepContent>
              <Stack gap={2}>
                <Typography fontSize={14}>{description}</Typography>
                <Fragment children={content} />
                <Box display='flex' gap={2} sx={{ mb: 2 }}>
                  {action.map((elem, index) => (
                    <Fragment children={elem} key={index} />
                  ))}
                </Box>
              </Stack>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
