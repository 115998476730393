import Container from '@mui/material/Container';
import StepperComponent from 'components/Stepper';

import AppBarComponent from './AppBar';

export default function SimpleContainer(): JSX.Element {
  return (
    <>
      <AppBarComponent />
      <Container maxWidth='lg'>
        <StepperComponent />
      </Container>
    </>
  );
}
