import { muiKey } from 'constants/mui';

import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-license-pro';

import Layout from './components/Layout';

LicenseInfo.setLicenseKey(muiKey);

function App() {
  return (
    <>
      <CssBaseline />
      <Layout />
    </>
  );
}

export default App;
