import { useAppDispatch, useAppSelector } from 'store';
import { selectInn } from 'store/selectors';
import { addInn, removeAllInn, removeInn } from 'store/slices/inn';

interface ReturnHook {
  inns: string[];
  addInns: (data: string[]) => void;
  removeInns: (item: string) => void;
  resetInn: () => void;
}

export default function useInn(): ReturnHook {
  const inns = useAppSelector(selectInn);
  const dispatch = useAppDispatch();

  const addInns = (data: string[]): void => {
    dispatch(addInn(data));
  };

  const removeInns = (item: string): void => {
    dispatch(removeInn(item));
  };

  const resetInn = (): void => {
    dispatch(removeAllInn());
  };

  return { addInns, removeInns, inns, resetInn };
}
