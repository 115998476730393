import { toLocale } from 'util/dates';

import { useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DoneIcon from '@mui/icons-material/Done';

import LinearProgressWithLabel from './LinearProgressWithLabel';

interface IProgressComponentProps {
  socket: Socket;
}

interface IParseData {
  percent: number;
  timeLeft: string;
  timePassed: string;
  count: number;
  startTime: Date | null;
  countInn: number;
}

export default function ProgressComponent({ socket }: IProgressComponentProps): JSX.Element {
  const [parseData, setParseData] = useState<IParseData>({
    percent: 0,
    timeLeft: '',
    timePassed: '',
    count: 0,
    startTime: null,
    countInn: 0,
  });
  const startTime = parseData.startTime ? toLocale(parseData.startTime) : 'Не определено';

  useEffect(
    () => {
      socket.on('parse', (arg) => setParseData(arg));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleCancel = (): void => {
    socket.emit('events', { type: 'stop' });
  };

  return (
    <Stack gap={2}>
      <LinearProgressWithLabel value={parseData.percent} />
      {parseData.countInn ? (
        <Stack gap={1} mt={2}>
          <Box display='flex' gap={0.2}>
            <CalendarMonthIcon color='warning' />
            <Typography>Запущено: {startTime}</Typography>
          </Box>

          <Box display='flex' gap={0.2}>
            <AssignmentIcon color='info' />
            <Typography>В работе: {parseData.countInn ? parseData.countInn : 0} ИНН</Typography>
          </Box>

          <Box display='flex' gap={0.2}>
            <HourglassBottomIcon color='primary' />
            <Typography>
              Прошло: {parseData.timePassed ? parseData.timePassed : '00 ч : 00 м : 00 с'}
            </Typography>
          </Box>

          <Box display='flex' gap={0.2}>
            <HourglassTopIcon color='error' />
            <Typography>
              Осталось: {parseData.timeLeft ? parseData.timeLeft : '00 ч : 00 м : 00 с'}
            </Typography>
          </Box>

          <Box display='flex' gap={0.2}>
            <DoneIcon color='success' />
            <Typography>
              Обработано: {parseData.count} из {parseData.countInn} ИНН
            </Typography>
          </Box>
        </Stack>
      ) : (
        <Box display='flex' my={5}>
          <Typography>Ожидание ответа сервера ...</Typography>
        </Box>
      )}
      <Box>
        <Button onClick={handleCancel} variant='contained' color='error' startIcon={<CancelIcon />}>
          Завершить
        </Button>
      </Box>
    </Stack>
  );
}
