import { utils, writeFile } from 'xlsx';

/**
 * @param data Массив данных для печати
 * @param callbackCreator функция, которая определят какие поля вносить в печать,
 * @example (data: IData) => ({
 *    'Название': data.name,
 *    'Тип': data.type,
 *    'Размер': data.size
 *    ...
 * })
 * @param settings объект опций, вкл. в себя расширение, название и имя таблицы в документе
 */
const convertAndSaveAsExcelFile = <T extends object, Y extends object>(
  data: T[],
  callbackCreator: CallbackCreator<T, Y>,
  settings: ISettings
): void => {
  // eslint-disable-next-line no-new-object
  const formattedArrayOfData = data.map((entity) => new Object(callbackCreator(entity)));

  const workBook = utils.book_new();
  const workSheet = utils.json_to_sheet(formattedArrayOfData);

  workSheet['!cols'] = settings.columnsWidth;
  utils.book_append_sheet(workBook, workSheet, settings.sheetName);
  writeFile(workBook, settings.fileName + settings.extension);
};

export type CallbackCreator<T extends object, Y extends object> = (data: T) => Y;

export interface ISettings {
  extension: string;
  fileName: string;
  sheetName: string;
  columnsWidth: { wpx: number }[];
}

export default convertAndSaveAsExcelFile;
