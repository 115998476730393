import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContragentState, IDataResponse } from 'types';

import fetchBeforeResult from './thunk/fetchBeforeResult';
import fetchPrevResult from './thunk/fetchPrevResult';
import fetchRealResult from './thunk/fetchRealResult';

const initialState: ContragentState = {
  status: 'idle',
  data: {} as IDataResponse,
};

const contragentSlice = createSlice({
  name: 'contragent',
  initialState,
  reducers: {
    setContragents: (state, action: PayloadAction<IDataResponse>) => {
      state.data = action.payload;
    },
    removeAllContragents: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPrevResult.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchPrevResult.fulfilled, (state, action) => {
      state.status = 'success';
      state.data = action.payload;
    });
    builder.addCase(fetchPrevResult.rejected, (state, action) => {
      state.status = 'error';
    });
    builder.addCase(fetchBeforeResult.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchBeforeResult.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    });
    builder.addCase(fetchBeforeResult.rejected, (state) => {
      state.status = 'error';
    });
    builder.addCase(fetchRealResult.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchRealResult.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    });
    builder.addCase(fetchRealResult.rejected, (state) => {
      state.status = 'error';
    });
  },
});
export const { removeAllContragents, setContragents } = contragentSlice.actions;
export default contragentSlice.reducer;
